import React from 'react';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

const NotFoundPage = () => (
  <Layout>
    <Header />
    <h1>NOT FOUND</h1>
    <p>Not a valid URL</p>
    <Footer />
  </Layout>
);

export default NotFoundPage;

